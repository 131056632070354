import styled from 'styled-components';

const Sponsor = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 9rem;
  overflow: hidden;
`

export default Sponsor;
