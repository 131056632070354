import React from "react";
import "./HomePage.css";
import Header from "../_components/Header";
import Button from "../_components/Button";
import HeaderMobile from "../_components/HeaderMobile";
import Footer from "../_components/Footer";
import Slider from "../_components/Slider";
import { Link } from "react-router-dom";
import Arrow from "./rightArrow.svg";
import Image1 from "./image1.jpg";
import Image2 from "./image2.jpg";
import Sponsors from "../_components/Sponsors";
import Media from "react-media";
import Countdown from "../_components/Countdown";

class HomePage extends React.Component {
  render() {
    return (
      <div>
        <div className="Home">
          <Media query={{ minWidth: 768 }}>{matches => (matches ? <Header /> : <HeaderMobile />)}</Media>
          <Slider />
          <section className="Home-countdown">
            <Media query={{ minWidth: 768 }}>
              {matches => (matches ? "" : <Countdown date="2025-05-10T08:15:00" />)}
            </Media>
          </section>
          <section className="Home-alert">
            <h2 className="slider-caption2">APERTURA DE INSCRIPCIONES 20 DE DICIEMBRE</h2>
          </section>
          <section className="Home-pager">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <a className="Home-pager-link" href="" target="_blank" rel="noopener noreferrer">
                    <h2 className="Home-pager-title">Inscritos</h2>
                    <h5 className="Home-pager-subtitle">Comprueba tu inscripción</h5>
                    <span className="Home-pager-arrow-wrapper">
                      <img className="Home-pager-arrow" src={Arrow} alt="arrow" />
                    </span>
                  </a>
                </div>
                <div className="col-md-12 col-lg-6">
                  <a
                    className="Home-pager-link"
                    href="https://www.321go.es/Carrera/Results?idCarrera=321"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h2 className="Home-pager-title">Resultados de la prueba</h2>
                    <h5 className="Home-pager-subtitle">Comprueba tu posición de esta edición</h5>
                    <span className="Home-pager-arrow-wrapper">
                      <img className="Home-pager-arrow" src={Arrow} alt="arrow" />
                    </span>
                  </a>
                  {/*                  <Link className="Home-pager-link" to="https://www.321go.es/Carrera/Results?idCarrera=321" target="_blank" rel="noopener noreferrer">
                    <h2 className="Home-pager-title">
                      <FormattedMessage
                        id="home.results"
                        defaultMessage="Resultados de la prueba"
                      />
                    </h2>
                    <h5 className="Home-pager-subtitle">
                      <FormattedMessage
                        id="home.results.checkPosition"
                        defaultMessage="Comprueba tu posición de esta edición"
                      />
                    </h5>
                    <span className="Home-pager-arrow-wrapper">
                      <img
                        className="Home-pager-arrow"
                        src={Arrow}
                        alt="arrow"
                      />
                    </span>
                  </Link>*/}
                </div>
              </div>
            </div>
          </section>
          {/*           <section className="plazas-agotadas-banner">
            <h1 className="mx-auto text-center py-4">APERTURA DE INSCRIPCIONES 20 DE DICIEMBRE</h1>
          </section> */}
          <Sponsors />
          <section className="Home-info">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 d-flex order-2 order-lg-1">
                  <article className="Home-info-text">
                    <h4 className="Home-info-title">X EDICIÓN</h4>
                    <p>
                      "PONTEVEDRA 4 PICOS BIKE - X EDICIÓN” es una prueba NO COMPETITIVA en la que los participantes
                      deberán ascender picos que rodean la ciudad de Pontevedra, pudiendo participar en la modalidad de
                      4 picos o 2 picos
                    </p>
                  </article>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 order-1 order-lg-2">
                  <div
                    className="Home-info-img"
                    style={{
                      backgroundImage: `url(${Image1})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "top center",
                      backgroundSize: "cover"
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </section>

          <section className="Home-banner">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div
                    className="Home-banner-img"
                    style={{
                      backgroundImage: `url(${Image2})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "top center",
                      backgroundSize: "cover"
                    }}
                  ></div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <article className="Home-banner-text">
                    <h6>10 de mayo de 2025</h6>
                    <h4 className="Home-banner-title">ATRÉVETE A PARTICIPAR</h4>
                    <p>
                      Décima edición en la ciudad de Pontevedra, en la que podrán participar deportistas y aficionados
                      en un reto único
                    </p>
                    <div className="Home-banner-button-wrapper">
                      <a
                        className="Home-banner-button"
                        href="https://sportmaniacs.com/c/pontevedra-4-picos-bike-desafo-ras-baixas-2024"
                      >
                        Inscríbete
                      </a>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </section>
          <Footer />
          <Media query={{ minWidth: 768 }}>
            {matches =>
              matches ? (
                ""
              ) : (
                <section className="inscription-fixed-bar">
                  <Button
                    className="inscription-fixed"
                    href="https://sportmaniacs.com/c/pontevedra-4-picos-bike-desafo-ras-baixas-2024"
                  >
                    Inscríbete
                  </Button>
                </section>
              )
            }
          </Media>
        </div>
      </div>
    );
  }
}

export default HomePage;
