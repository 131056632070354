import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Logo";
import "./Header.css";
import { FormattedMessage } from "react-intl";
import LanguageSwitcher from "../LanguageSwitcher";
import Dropdown from "react-multilevel-dropdown";

class Header extends React.Component {
  render() {
    return (
      <header className="Header">
        {/* <div className="Header-top">
          <LanguageSwitcher />
        </div> */}
        <div className="Header-container">
          <Logo />
          <nav className="Header-navbar">
            <ul className="Header-list">
              <li className="Header-item">
                <Dropdown
                  buttonClassName="Header-link Header-link-dropdown"
                  title="La prueba"
                >
                  <Dropdown.Item className="dropdown-item">
                    <Link className="Header-link" to="/descripcion">
                      Descripción
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item">
                    <Link className="Header-link" to="/tarifas">
                      Tarifas
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item">
                    <Link className="Header-link" to="/reglamento">
                      Reglamento
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item">
                    <Link className="Header-link" to="/tracks">
                      Tracks
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item">
                    <Link className="Header-link" to="/seguro-devolucion">
                      Seguro de devolución
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item">
                    <Link className="Header-link" to="/preguntas-frecuentes">
                      Preguntas frecuentes
                    </Link>
                  </Dropdown.Item>
                </Dropdown>
              </li>
              <li className="Header-item">
                <Dropdown
                  buttonClassName="Header-link Header-link-dropdown"
                  title="Multimedia"
                >
                  <Dropdown.Item className="dropdown-item">
                    <Link className="Header-link" to="/fotos">
                      Fotos
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item">
                    <Link className="Header-link" to="/videos">
                      Vídeos
                    </Link>
                  </Dropdown.Item>
                </Dropdown>
              </li>
              <li className="Header-item">
                <Dropdown
                  buttonClassName="Header-link Header-link-dropdown"
                  title="Nuestras pruebas"
                >
                  <Dropdown.Item className="Header-link">
                    Pruebas Global
                    <Dropdown.Submenu>
                      <Dropdown.Item className="dropdown-item">
                        <a
                          className="Header-link"
                          href="http://www.pontevedra4picosroad.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Pontevedra 4 Picos Road
                        </a>
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <a
                          className="Header-link"
                          href="http://www.epicracepontevedra.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Epic Race Pontevedra
                        </a>
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <a
                          className="Header-link"
                          href="http://www.costaatlanticamtbtour.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Costa Atlántica MTB Tour
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Submenu>
                  </Dropdown.Item>
                  <Dropdown.Item className="Header-link">
                    Pruebas amigas
                    <Dropdown.Submenu>
                      <Dropdown.Item className="dropdown-item">
                        <a
                          className="Header-link"
                          href="https://mountainquest.pt/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Mountain Quest
                        </a>
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <a
                          className="Header-link"
                          href="https://dourobikerace.com/es/dbr/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Douro Bike Race
                        </a>
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <a
                          className="Header-link"
                          href="https://www.gigantedepiedra.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Gigante de Piedra
                        </a>
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <a
                          className="Header-link"
                          href="https://www.amigosdamontanha.com/_2022_-_maratona_btt_5_cumes"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Maratona BTT 5 Cumes
                        </a>
                      </Dropdown.Item>                      
                      <Dropdown.Item className="dropdown-item">
                        <a
                          className="Header-link"
                          href="https://laleyendadetartessos.com/?gclid=Cj0KCQjwhL6pBhDjARIsAGx8D5-LiCEEJ6BMYVgycxlkgP-F2zhFalCPWFNcb_kWB3B_K1VNuUwZhA4aAuAPEALw_wcB"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          La Leyenda de Tartesos
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Submenu>
                  </Dropdown.Item>
                </Dropdown>
              </li>
              <li className="Header-item">
                <Link className="Header-link" to="/alojamiento">
                  <FormattedMessage
                    id="header.hosting"
                    defaultMessage="Alojamiento"
                  />
                </Link>
              </li>
              <li className="Header-item">
                <Link className="Header-link" to="/sponsors">
                  Sponsors
                </Link>
              </li>
              <li className="Header-item Header-item-inscription">
                <a
                  className="Header-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://sportmaniacs.com/c/pontevedra-4-picos-bike-desafo-ras-baixas-2024"
                >
                  <FormattedMessage
                    id="header.enroll"
                    defaultMessage="Inscríbete"
                  />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
