import React from 'react';
import './HeaderMobile.css';
import Logo from '../Logo';
import Menu from './Menu';
import MenuButton from './MenuButton';

class HeaderMobile extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      visible: false
    };

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }
 
  handleMouseDown(e) {
    this.toggleMenu();
 
    e.stopPropagation();
  }
 
  toggleMenu() {
    this.setState(
      {
        visible: !this.state.visible
      }
    );
  }

  render() {
    return (
      <header className="header-mobile">
        <div className="header-wrapper">
          <Logo />
          <MenuButton handleMouseDown={this.handleMouseDown} />
          <Menu handleMouseDown={this.handleMouseDown} menuVisibility={this.state.visible}/>
        </div>
      </header>
    )
  }
}

export default HeaderMobile;