import image1 from "./images/2022__K8A0082.jpg";
import image2 from "./images/2022__K8A0091.jpg";
import image3 from "./images/2022__K8A0104.jpg";
import image4 from "./images/2022__K8A0110.jpg";
import image5 from "./images/2022__K8A0144.jpg";
import image6 from "./images/2022__K8A0146.jpg";
import image7 from "./images/2022__K8A0155.jpg";
import image8 from "./images/2022__K8A0174.jpg";
import image9 from "./images/2022__K8A0248.jpg";
import image10 from "./images/2022__K8A0270.jpg";
import image11 from "./images/2022__K8A0285.jpg";
import image12 from "./images/2022__K8A0288.jpg";
import image13 from "./images/2022__K8A0290.jpg";
import image14 from "./images/2022__K8A0321.jpg";
import image15 from "./images/2022__K8A0322.jpg";
import image16 from "./images/2022__K8A0333.jpg";
import image17 from "./images/2022__K8A0538.jpg";
import image18 from "./images/2022__K8A8295.jpg";
import image19 from "./images/2022_2A3A0040.jpg";
import image20 from "./images/2022_2A3A0709.jpg";
import image21 from "./images/2022_2A3A0796.jpg";
import image22 from "./images/2022_2A3A0806.jpg";
import image23 from "./images/2022_2A3A0838.jpg";
import image24 from "./images/2022_2A3A0875.jpg";
import image25 from "./images/2022_2A3A0904.jpg";
import image26 from "./images/2022_2A3A1125.jpg";
import image27 from "./images/2022_2A3A1216.jpg";
import image28 from "./images/2022_2A3A1594.jpg";
import image29 from "./images/2022_2A3A9545.jpg";
import image30 from "./images/2022_2A3A9564.jpg";
import image31 from "./images/2022_2A3A9623.jpg";
import image32 from "./images/2022_2A3A9672.jpg";
import image33 from "./images/2022_2A3A9679.jpg";
import image34 from "./images/2022_2A3A9720.jpg";
import image35 from "./images/2022_2A3A9926.jpg";
import image36 from "./images/2022_4G8A1123.jpg";
import image37 from "./images/2022_4G8A1137.jpg";
import image38 from "./images/2022_IMG_9018.jpg";
import image39 from "./images/2022_IMG_9020.jpg";
import image40 from "./images/2022_IMG_9066.jpg";
import image41 from "./images/2022_IMG_9091.jpg";

const images2022 = [
  { src: image1, width: 830, height: 645 },
  { src: image2, width: 830, height: 645 },
  { src: image3, width: 830, height: 645 },
  { src: image4, width: 830, height: 645 },
  { src: image5, width: 830, height: 645 },
  { src: image6, width: 830, height: 645 },
  { src: image7, width: 830, height: 645 },
  { src: image8, width: 830, height: 645 },
  { src: image9, width: 830, height: 645 },
  { src: image10, width: 830, height: 645 },
  { src: image11, width: 830, height: 645 },
  { src: image12, width: 830, height: 645 },
  { src: image13, width: 830, height: 645 },
  { src: image14, width: 830, height: 645 },
  { src: image15, width: 830, height: 645 },
  { src: image16, width: 830, height: 645 },
  { src: image17, width: 830, height: 645 },
  { src: image18, width: 830, height: 645 },
  { src: image19, width: 830, height: 645 },
  { src: image20, width: 830, height: 645 },
  { src: image21, width: 830, height: 645 },
  { src: image22, width: 830, height: 645 },
  { src: image23, width: 830, height: 645 },
  { src: image24, width: 830, height: 645 },
  { src: image25, width: 830, height: 645 },
  { src: image26, width: 830, height: 645 },
  { src: image27, width: 830, height: 645 },
  { src: image28, width: 830, height: 645 },
  { src: image29, width: 830, height: 645 },
  { src: image30, width: 830, height: 645 },
  { src: image31, width: 830, height: 645 },
  { src: image32, width: 830, height: 645 },
  { src: image33, width: 830, height: 645 },
  { src: image34, width: 830, height: 645 },
  { src: image35, width: 830, height: 645 },
  { src: image36, width: 830, height: 645 },
  { src: image37, width: 830, height: 645 },
  { src: image38, width: 830, height: 645 },
  { src: image39, width: 830, height: 645 },
  { src: image40, width: 830, height: 645 },
  { src: image41, width: 830, height: 645 },
];

export default images2022;
