import React from 'react';
import './Footer.css';
import ImgFooter from './imgFooter.jpg';

class Footer extends React.Component {
  render() {
    return (
      <footer className="Footer">
        <div className="Footer-top">
          <div className="Footer-top-wrapper" 
            style={{
              backgroundImage: `url(${ImgFooter})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top',
              height: '600px',
              width: '100%'
            }}>
          </div>
        </div>
        <div className="Footer-bottom">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p className="m0">© 2018 Copyright Globaldxt S.L.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;