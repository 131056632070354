import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { IntlProvider, loadLocaleData } from "./_components/ReactIntlContextProvider";

loadLocaleData(['es', 'gl']);

ReactDOM.render(
  <IntlProvider initialProps={{ locale: "es", defaultLocale: "es" }}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </IntlProvider>, 
  document.getElementById('root')
);
serviceWorker.unregister();
