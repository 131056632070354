import image1 from "./images/2019_4G8A5305.jpg";
import image2 from "./images/2019_4G8A5306.jpg";
import image3 from "./images/2019_4G8A5330.jpg";
import image4 from "./images/2019_4G8A5710.jpg";
import image5 from "./images/2019_4G8A5719.jpg";
import image6 from "./images/2019_4G8A5764.jpg";
import image7 from "./images/2019_4G8A5798.jpg";
import image8 from "./images/2019_4G8A5954.jpg";
import image9 from "./images/2019_4G8A6017.jpg";
import image10 from "./images/2019_4G8A7354.jpg";
import image11 from "./images/2019_4G8A7369.jpg";
import image12 from "./images/2019_4G8A7994.jpg";
import image13 from "./images/2019_IMG_2026.jpg";
import image14 from "./images/2019_IMG_2045.jpg";
import image15 from "./images/2019_IMG_2049.jpg";
import image16 from "./images/2019_IMG_2052.jpg";
import image17 from "./images/2019_IMG_4120.jpg";
import image18 from "./images/2019_IMG_8136.jpg";
import image19 from "./images/2019_IMG_8141.jpg";
import image20 from "./images/2019_IMG_8153.jpg";
import image21 from "./images/2019_IMG_9526.jpg";
import image22 from "./images/2019_IMG_9620.jpg";
import image23 from "./images/2019_IMG_9624.jpg";

const images2019 = [
  { src: image1, width: 830, height: 645 },
  { src: image2, width: 830, height: 645 },
  { src: image3, width: 830, height: 645 },
  { src: image4, width: 830, height: 645 },
  { src: image5, width: 830, height: 645 },
  { src: image6, width: 830, height: 645 },
  { src: image7, width: 830, height: 645 },
  { src: image8, width: 830, height: 645 },
  { src: image9, width: 830, height: 645 },
  { src: image10, width: 830, height: 645 },
  { src: image11, width: 830, height: 645 },
  { src: image12, width: 830, height: 645 },
  { src: image13, width: 830, height: 645 },
  { src: image14, width: 830, height: 645 },
  { src: image15, width: 830, height: 645 },
  { src: image16, width: 830, height: 645 },
  { src: image17, width: 830, height: 645 },
  { src: image18, width: 830, height: 645 },
  { src: image19, width: 830, height: 645 },
  { src: image20, width: 830, height: 645 },
  { src: image21, width: 830, height: 645 },
  { src: image22, width: 830, height: 645 },
  { src: image23, width: 830, height: 645 },
];

export default images2019;
