import styled from 'styled-components';
import { darken } from 'polished';

const Button = styled.a`
  position: relative;
  display: inline-block;
  padding: 0.5rem 0;
  margin: 0.5rem 1rem;
  width: 10rem;
  font-size: 0.75rem;
  border: 1px solid transparent;
  color: white;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  z-index: 1;
  transition: all 0.15s ease;
  &::before {
    content: '';
    transform: skewX(-14deg);
    transition: background-color .2s ease-in-out;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #c21718;
    z-index: -1;
  }
  &:hover::before {
    background-color: ${darken(0.1, '#c21718')};
  }
  &:hover {
    color: white;
    text-decoration: none;
  }
`

export default Button;