import image1 from "./images/2021_2A3A0766.jpg";
import image2 from "./images/2021_2A3A1079.jpg";
import image3 from "./images/2021_4G8A7963.jpg";
import image4 from "./images/2021_4G8A8421.jpg";
import image5 from "./images/2021_4G8A8668.jpg";
import image6 from "./images/2021_4G8A8816.jpg";
import image7 from "./images/2021_HK8A0075.jpg";
import image8 from "./images/2021_IMG_1768.jpg";
import image9 from "./images/2021_4G8A8744.jpg";
import image10 from "./images/2021_4G8A8809.jpg";
import image11 from "./images/2021_2A3A0774.jpg";
import image12 from "./images/2021_2A3A0880.jpg";
import image13 from "./images/2021_2A3A0986.jpg";
import image14 from "./images/2021_2A3A1051.jpg";
import image15 from "./images/2021_2A3A1070.jpg";
import image16 from "./images/2021_2A3A1173.jpg";
import image17 from "./images/2021_2A3A2942.jpg";
import image19 from "./images/2021_2A3A2980.jpg";
import image20 from "./images/2021_2A3A3027.jpg";
import image21 from "./images/2021_2A3A3033.jpg";
import image22 from "./images/2021_4G8A8017.jpg";
import image23 from "./images/2021_4G8A8023.jpg";
import image24 from "./images/2021_4G8A8151.jpg";
import image25 from "./images/2021_4G8A8184.jpg";
import image26 from "./images/2021_4G8A8408.jpg";
import image27 from "./images/2021_4G8A8426.jpg";
import image28 from "./images/2021_4G8A8442.jpg";
import image29 from "./images/2021_4G8A8465.jpg";
import image30 from "./images/2021_4G8A8471.jpg";
import image31 from "./images/2021_4G8A8531.jpg";
import image32 from "./images/2021_4G8A8699.jpg";
import image33 from "./images/2021_4G8A8704.jpg";
import image34 from "./images/2021_4G8A8731.jpg";

const images2021 = [
  { src: image1, width: 830, height: 645 },
  { src: image2, width: 830, height: 645 },
  { src: image3, width: 830, height: 645 },
  { src: image4, width: 830, height: 645 },
  { src: image5, width: 830, height: 645 },
  { src: image6, width: 830, height: 645 },
  { src: image7, width: 830, height: 645 },
  { src: image8, width: 830, height: 645 },
  { src: image9, width: 830, height: 645 },
  { src: image10, width: 830, height: 645 },
  { src: image11, width: 830, height: 645 },
  { src: image12, width: 830, height: 645 },
  { src: image13, width: 830, height: 645 },
  { src: image14, width: 830, height: 645 },
  { src: image15, width: 830, height: 645 },
  { src: image16, width: 830, height: 645 },
  { src: image17, width: 830, height: 645 },
  { src: image19, width: 830, height: 645 },
  { src: image20, width: 830, height: 645 },
  { src: image21, width: 830, height: 645 },
  { src: image22, width: 830, height: 645 },
  { src: image23, width: 830, height: 645 },
  { src: image24, width: 830, height: 645 },
  { src: image25, width: 830, height: 645 },
  { src: image26, width: 830, height: 645 },
  { src: image27, width: 830, height: 645 },
  { src: image28, width: 830, height: 645 },
  { src: image29, width: 830, height: 645 },
  { src: image30, width: 830, height: 645 },
  { src: image31, width: 830, height: 645 },
  { src: image32, width: 830, height: 645 },
  { src: image33, width: 830, height: 645 },
  { src: image3, width: 830, height: 645 },
];

export default images2021;
