import React from 'react';
import Ham from './menu.svg';

class MenuButton extends React.Component {
  render () {
    return(
      <button className="btn" onMouseDown={this.props.handleMouseDown}>
        <img src={Ham} alt="icon"/>
      </button>
    )
  }
}

export default MenuButton;