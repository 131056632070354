import React from 'react';
import rightArrow from './rightArrow.svg';

const RightArrow = (props) => {
  return (
    <div className="right-arrow" onClick={props.goToNextSlide}>
      <img src={rightArrow} alt="arrow"/>
    </div>
  );
}

export default RightArrow;