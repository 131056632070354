import image1 from "./images/2023_1.jpg";
import image2 from "./images/2023_2.jpg";
import image3 from "./images/2023_3.jpg";
import image4 from "./images/2023_4.jpg";
import image5 from "./images/2023_5.jpg";
import image6 from "./images/2023_6.jpg";
import image7 from "./images/2023_7.jpg";
import image8 from "./images/2023_8.jpg";
import image9 from "./images/2023_9.jpg";
import image10 from "./images/2023_10.jpg";
import image11 from "./images/2023_11.jpg";
import image12 from "./images/2023_12.jpg";
import image13 from "./images/2023_13.jpg";
import image14 from "./images/2023_14.jpg";
import image15 from "./images/2023_15.jpg";
import image16 from "./images/2023_16.jpg";
import image17 from "./images/2023_17.jpg";
import image18 from "./images/2023_18.jpg";
import image19 from "./images/2023_19.jpg";
import image20 from "./images/2023_20.jpg";
import image21 from "./images/2023_21.jpg";
import image22 from "./images/2023_22.jpg";
import image23 from "./images/2023_23.jpg";
import image24 from "./images/2023_24.jpg";
import image25 from "./images/2023_25.jpg";
import image26 from "./images/2023_26.jpg";
import image27 from "./images/2023_27.jpg";
import image28 from "./images/2023_28.jpg";
import image29 from "./images/2023_29.jpg";
import image30 from "./images/2023_30.jpg";
import image31 from "./images/2023_31.jpg";
import image32 from "./images/2023_32.jpg";
import image33 from "./images/2023_33.jpg";
import image34 from "./images/2023_34.jpg";
import image35 from "./images/2023_35.jpg";
import image36 from "./images/2023_36.jpg";
import image37 from "./images/2023_37.jpg";
import image38 from "./images/2023_38.jpg";
import image39 from "./images/2023_39.jpg";
import image40 from "./images/2023_40.jpg";
import image41 from "./images/2023_41.jpg";
import image42 from "./images/2023_42.jpg";
import image43 from "./images/2023_43.jpg";
import image44 from "./images/2023_44.jpg";
import image45 from "./images/2023_45.jpg";

const images2023 = [
    { src: image1, width: 830, height: 645 },
    { src: image2, width: 830, height: 645 },
    { src: image3, width: 830, height: 645 },
    { src: image4, width: 830, height: 645 },
    { src: image5, width: 830, height: 645 },
    { src: image6, width: 830, height: 645 },
    { src: image7, width: 830, height: 645 },
    { src: image8, width: 830, height: 645 },
    { src: image9, width: 830, height: 645 },
    { src: image10, width: 830, height: 645 },
    { src: image11, width: 830, height: 645 },
    { src: image12, width: 830, height: 645 },
    { src: image13, width: 830, height: 645 },
    { src: image14, width: 830, height: 645 },
    { src: image15, width: 830, height: 645 },
    { src: image16, width: 830, height: 645 },
    { src: image17, width: 830, height: 645 },
    { src: image18, width: 830, height: 645 },
    { src: image19, width: 830, height: 645 },
    { src: image20, width: 830, height: 645 },
    { src: image21, width: 830, height: 645 },
    { src: image22, width: 830, height: 645 },
    { src: image23, width: 830, height: 645 },
    { src: image24, width: 830, height: 645 },
    { src: image25, width: 830, height: 645 },
    { src: image26, width: 830, height: 645 },
    { src: image27, width: 830, height: 645 },
    { src: image28, width: 830, height: 645 },
    { src: image29, width: 830, height: 645 },
    { src: image30, width: 830, height: 645 },
    { src: image31, width: 830, height: 645 },
    { src: image32, width: 830, height: 645 },
    { src: image33, width: 830, height: 645 },
    { src: image34, width: 830, height: 645 },
    { src: image35, width: 830, height: 645 },
    { src: image36, width: 830, height: 645 },
    { src: image37, width: 830, height: 645 },
    { src: image38, width: 830, height: 645 },
    { src: image39, width: 830, height: 645 },
    { src: image40, width: 830, height: 645 },
    { src: image41, width: 830, height: 645 },
    { src: image42, width: 830, height: 645 },
    { src: image43, width: 830, height: 645 },
    { src: image44, width: 830, height: 645 },
    { src: image45, width: 830, height: 645 },
];

export default images2023;
