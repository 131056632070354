import React from "react";
import Header from "../_components/Header";
import Footer from "../_components/Footer";
import ImageHero from "./image1.jpg";
import "./VideosPage.css";
import { FormattedMessage } from "react-intl";
import Media from "react-media";
import HeaderMobile from "../_components/HeaderMobile";

import * as Tabs from "@radix-ui/react-tabs";
import Button from "../_components/Button";
const VideosPage = () => {
  return (
    <div className="Media">
      <Media query={{ minWidth: 768 }}>
        {(matches) => (matches ? <Header /> : <HeaderMobile />)}
      </Media>
      <section
        className="Media-hero"
        style={{
          height: "350px",
          width: "100%",
          backgroundImage: `url(${ImageHero})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
        }}
      ></section>
      <section className="Media-banner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <p>
APERTURA DE INSCRIPCIONES 20 DE DICIEMBRE
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="Media-info">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h3 className="Media-title">Vídeos</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="m-5">
        <Tabs.Root defaultValue="2024" orientation="vertical">
          <Tabs.List className="tab-header-list" aria-label="tabs photos">
            <Tabs.Trigger className="tab-header-item" value="2019">
              2019
            </Tabs.Trigger>
            <Tabs.Trigger className="tab-header-item" value="2021">
              2021
            </Tabs.Trigger>
            <Tabs.Trigger className="tab-header-item" value="2022">
              2022
            </Tabs.Trigger>
            <Tabs.Trigger className="tab-header-item" value="2023">
              2023
            </Tabs.Trigger>
            <Tabs.Trigger className="tab-header-item" value="2024">
              2024
            </Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content className="tab-content-wrapper" value="2019">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/jBUJSVWH_JU"
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/r4QvYJoOBzc"
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </Tabs.Content>
          <Tabs.Content className="tab-content-wrapper" value="2021">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/XiDQZyBuKGc"
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/nLi-sgQCr6c"
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </Tabs.Content>
          <Tabs.Content className="tab-content-wrapper" value="2022">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/AC1UpXYl-bk"
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/2qqCj59sEw0"
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </Tabs.Content>
          <Tabs.Content className="tab-content-wrapper" value="2023">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/JI-gZ0oxcyw"
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          <div class="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/JdH6UJ0RCo0?si=fizdGaRhdnfDerUt"
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </Tabs.Content>
          <Tabs.Content className="tab-content-wrapper" value="2024">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/JAJOPxTeuaQ?si=ilU8qIJmsdb1c3se"
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/muLP92Gv3VM?si=lEDut421MtQaSY_E"
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </Tabs.Content>
        </Tabs.Root>
      </section>
      <Footer />
      <Media query={{ minWidth: 768 }}>
        {(matches) =>
          matches ? (
            ""
          ) : (
            <section className="inscription-fixed-bar">
              <Button
                className="inscription-fixed"
                href="https://sportmaniacs.com/c/pontevedra-4-picos-bike-desafo-ras-baixas-2024"
                target="_blank"
                rel="noopener noreferrer"
              >
                Inscríbete
              </Button>
            </section>
          )
        }
      </Media>
    </div>
  );
};

export default VideosPage;
