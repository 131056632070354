import React from 'react';
import { Route } from 'react-router-dom';
import HomePage from '../HomePage';
import RatesPage from '../RatesPage';
import RulesPage from '../RulesPage';
import PhotosPage from '../PhotosPage';
import VideosPage from '../VideosPage';
import TracksPage from '../TracksPage';
import HostingPage from '../HostingPage';
import SponsorsPage from '../SponsorsPage';
import SeguroPage from '../SeguroPage';
import DescriptionPage from '../DescriptionPage';
import PreguntasPage from '../PreguntasPage';
class App extends React.Component {
  render() {
    return (
      <div>
        <Route exact path="/" component={HomePage} />
        <Route path="/descripcion" component={DescriptionPage} />
        <Route path="/tarifas" component={RatesPage} />
        <Route path="/reglamento" component={RulesPage} />
        <Route path="/seguro-devolucion" component={SeguroPage} />
        <Route path="/preguntas-frecuentes" component={PreguntasPage} />
        <Route path="/tracks" component={TracksPage} />
        <Route path="/fotos" component={PhotosPage} />
        <Route path="/videos" component={VideosPage} />
        <Route path="/alojamiento" component={HostingPage} />
        <Route path="/sponsors" component={SponsorsPage} />
      </div>
    );
  }
}

export default App;