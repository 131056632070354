import image1 from "./images/2024_1.jpg";
import image2 from "./images/2024_2.jpg";
import image3 from "./images/2024_3.jpg";
import image4 from "./images/2024_4.jpg";
import image5 from "./images/2024_5.jpg";
import image6 from "./images/2024_6.jpg";
import image7 from "./images/2024_7.jpg";
import image8 from "./images/2024_8.jpg";
import image9 from "./images/2024_9.jpg";
import image10 from "./images/2024_10.jpg";
import image11 from "./images/2024_11.jpg";
import image12 from "./images/2024_12.jpg";
import image13 from "./images/2024_13.jpg";
import image14 from "./images/2024_14.jpg";
import image15 from "./images/2024_15.jpg";
import image16 from "./images/2024_16.jpg";
import image17 from "./images/2024_17.jpg";
import image18 from "./images/2024_18.jpg";
import image19 from "./images/2024_19.jpg";
import image20 from "./images/2024_20.jpg";
import image21 from "./images/2024_21.jpg";
import image22 from "./images/2024_22.jpg";
import image23 from "./images/2024_23.jpg";
import image24 from "./images/2024_24.jpg";
import image25 from "./images/2024_25.jpg";
import image26 from "./images/2024_26.jpg";
import image27 from "./images/2024_27.jpg";
import image28 from "./images/2024_28.jpg";
import image29 from "./images/2024_29.jpg";
import image30 from "./images/2024_30.jpg";
import image31 from "./images/2024_31.jpg";
import image32 from "./images/2024_32.jpg";
import image33 from "./images/2024_33.jpg";
import image34 from "./images/2024_34.jpg";
import image35 from "./images/2024_35.jpg";
import image36 from "./images/2024_36.jpg";
import image37 from "./images/2024_37.jpg";
import image38 from "./images/2024_38.jpg";
import image39 from "./images/2024_39.jpg";
import image40 from "./images/2024_40.jpg";

const images2024 = [
    { src: image1, width: 830, height: 645 },
    { src: image2, width: 830, height: 645 },
    { src: image3, width: 830, height: 645 },
    { src: image4, width: 830, height: 645 },
    { src: image5, width: 830, height: 645 },
    { src: image6, width: 830, height: 645 },
    { src: image7, width: 830, height: 645 },
    { src: image8, width: 830, height: 645 },
    { src: image9, width: 830, height: 645 },
    { src: image10, width: 830, height: 645 },
    { src: image11, width: 830, height: 645 },
    { src: image12, width: 830, height: 645 },
    { src: image13, width: 830, height: 645 },
    { src: image14, width: 830, height: 645 },
    { src: image15, width: 830, height: 645 },
    { src: image16, width: 830, height: 645 },
    { src: image17, width: 830, height: 645 },
    { src: image18, width: 830, height: 645 },
    { src: image19, width: 830, height: 645 },
    { src: image20, width: 830, height: 645 },
    { src: image21, width: 830, height: 645 },
    { src: image22, width: 830, height: 645 },
    { src: image23, width: 830, height: 645 },
    { src: image24, width: 830, height: 645 },
    { src: image25, width: 830, height: 645 },
    { src: image26, width: 830, height: 645 },
    { src: image27, width: 830, height: 645 },
    { src: image28, width: 830, height: 645 },
    { src: image29, width: 830, height: 645 },
    { src: image30, width: 830, height: 645 },
    { src: image31, width: 830, height: 645 },
    { src: image32, width: 830, height: 645 },
    { src: image33, width: 830, height: 645 },
    { src: image34, width: 830, height: 645 },
    { src: image35, width: 830, height: 645 },
    { src: image36, width: 830, height: 645 },
    { src: image37, width: 830, height: 645 },
    { src: image38, width: 830, height: 645 },
    { src: image39, width: 830, height: 645 },
    { src: image40, width: 830, height: 645 },
];

export default images2024;
