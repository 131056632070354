import styled from 'styled-components';

const SponsorsContainer = styled.div`
  height: 15rem;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
`

export default SponsorsContainer;
