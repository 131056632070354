import styled from 'styled-components';

const SponsorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 16.66666%;
  height: auto;
  padding: 1rem;
  transition: transform ease-out 0.50s;
  @media screen and (max-width: 768px) {
    flex: 0 0 50%;
  }
`

export default SponsorWrapper;
