import React from 'react';
import { IntlConsumer } from "../ReactIntlContextProvider";
import esTranslation from "../../_translations/es.json";
import glTranslation from "../../_translations/gl.json";
import './LanguageSwitcher.css';
import { FormattedMessage } from 'react-intl';

const LanguageSwitcher = () => (
  <IntlConsumer>
    {({ updateProps, ...providerProps }) => (
      <>
      <div className="Language-switcher">
        <button onClick={() => updateProps({ locale: "es", messages: esTranslation })}>
          <FormattedMessage id="languageSwitcher.spanish" defaultMessage="Español" />
        </button>
        <span> | </span>
        <button onClick={() => updateProps({ locale: "gl", messages: glTranslation })}>
        <FormattedMessage id="languageSwitcher.galician" defaultMessage="Gallego" />
        </button>
      </div>
      </>
    )}
  </IntlConsumer>
);

export default LanguageSwitcher;